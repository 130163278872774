import React, { useEffect, useRef, useState } from 'react';
import SignaturePadComponent from './SignaturePadComponent';
import TypeSignatureComponent from './TypeSignatureComponent';
import UploadSignatureFile from './UploadSignatureFile';
import moment from "moment";
const DigitalSignatureComponent = ({signaturePadRef,fileInputRef,formik}) => {
  const [signatureType, setSignatureType] = useState(0);
  const [fetchDateNIp, setFetchDateNIp] = useState(false);
  const [ipAddress, setIpAddress] = useState('');
  const [cDate,setCDate] = useState('')
  useEffect(() => {
    if(fetchDateNIp){
      fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        console.log('Your IP address is:', data.ip);
        setIpAddress(data.ip);
        formik.setFieldValue('ip', data.ip)
      })
    .catch(error => {
      console.error('Error fetching IP address:', error);
    });
    setCDate(moment(new Date().getTime()).format('DD MMM YYYY, HH:mm'))
    }else{
      setIpAddress('')
      setCDate('')
    }
  },[fetchDateNIp])
  
    return (
        <div className='mb-3'>
            <div className="mt-3 mb-1">Signature*</div>
                <div className="d-flex gap-2 mb-1 py-1">
                  <div className="border-b-black" style={{borderBottom : signatureType == 0 ? '1px #467FCF solid' : '0',color : signatureType == 0 ? '#467FCF' : fetchDateNIp ? 'gray' : 'black',cursor:'pointer'}} onClick={() => {!fetchDateNIp && setSignatureType(0)}}>
                    <i className="fal fa-signature"></i>
                    <span className="ml-1">Draw</span>
                  </div>
                  <div className="border-b-black" style={{borderBottom : signatureType == 1 ? '1px #467FCF solid' : '0',color : signatureType == 1 ? '#467FCF' : fetchDateNIp ? 'gray' : 'black',cursor:'pointer'}} onClick={() => {!fetchDateNIp &&  setSignatureType(1)}}>
                    <i className="fal fa-image"></i>
                    <span className="ml-1">Upload Image </span>
                  </div>
                </div>
                {signatureType == 0 && (
                  <>
                    <SignaturePadComponent setShowIp={setFetchDateNIp} formik={formik} showIp={fetchDateNIp} signaturePadRef={signaturePadRef}/>
                    {/* <i className="fal fa-info fa-circle"></i> */}
                  </>
                )}
                {signatureType == 1 && 
                  <UploadSignatureFile setShowIp={setFetchDateNIp} formik={formik} showIp={fetchDateNIp} fileInputRef={fileInputRef}/>
                }
                <div className='mt-2'>
                <span><i className='fal fa-info-circle mr-1'></i><b>IP:</b> {ipAddress}</span>
                <span className='ml-2'><b>Date: </b>{cDate}</span>
                </div>
        </div>
    );
}
export default DigitalSignatureComponent;