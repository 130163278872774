import React, { useEffect } from 'react'
import { TrimText } from '../common/TrimText';
import { useDispatch } from 'react-redux';
import SkeletonSubjectHeadBox from '../../loaders/SekletonSubjectHeadBox';
import SekletonLearningMaterial from '../../loaders/SekletonLearningMaterial';
import SekletonSubjectList from '../../loaders/SekletonSubjectList';
import { updateActivityBtnAction } from '../../store/actions';

function AssemblyItemBox({ assemblies, setModuleId, setshow, setLearningTab,  setAssemblyCategoryList,loading,setLessonList}) {
    const dispatch = useDispatch();
    const handleAssemblyClick = (ele) => {
        dispatch(updateActivityBtnAction('continue'))
        setshow({ cards: false, list: true, desc: true })
        setLessonList(ele)
        setLearningTab(true)
        setAssemblyCategoryList(ele)
        for (let i = 0; i < ele?.assembliesItems?.length; i++) {
            const currentItem = ele.assembliesItems[i];
    
            if (currentItem?.lessons?.length) {
                setModuleId(currentItem?.item_id); 
                break;
            }
        }
    }
    return (
        <>
            {loading && <SekletonSubjectList />}
            {!loading && assemblies?.length > 0 ? (<div className="">
                <h3 className='learning__material__overview__text'>Learning Material Overview</h3>
                <div className='assembly__items__list row flex-direction-column row-custome couses-newrw-lists'>
                    {assemblies?.map((ele, i) => {
                        // if (ele?.subject_type === "shortcourse") {
                        return (
                            <div
                                className="col-lg-6 col-md-6 col-xs-12 qualification-box"
                                key={ele.id}
                                title={ele.name}
                                onClick={() => handleAssemblyClick(ele)}
                            >
                                <div
                                    className={`assembly__list__cards couses-renew-card home-bx-card qual-tab bg-white ${ele?.subjectStatus === "Not Yet Published"
                                        ? " short-course-disabled"
                                        : ""
                                        }`}
                                >
                                    <>
                                        <div className="course-box w-100">
                                            <div>
                                                {/* <div className="short-course-title">
                                                <div className="program-status">
                                                    <p className="program-status-type">
                                                        {ele?.subjectStatus}
                                                    </p>
                                                </div>
                                            </div> */}

                                                <div className="program-tittle">
                                                    <h2 title={ele.name || "NA"} style={{ padding: "0" }}>
                                                        {ele.name ? TrimText(ele.name, 50) : "NA"}
                                                    </h2>
                                                </div>

                                                {/* <div className="d-flex flex-direction-column align-items-center program-duration">
                                            {ele.type === "Self-Paced" ? (
                                                <p
                                                    className="months-duration"
                                                    style={{
                                                        marginRight: "auto",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    <span className="couse_i_in">
                                                        <i className="fal fa-clock"></i>
                                                    </span>
                                                    {ele?.subject_duration} months
                                                </p>
                                            ) : (
                                                <p
                                                    className="date-duration"
                                                    style={{
                                                        marginRight: "auto",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    <span className="couse_i_in">
                                                        <i className="fal fa-calendar-alt"></i>
                                                    </span>
                                                    {ele.start
                                                        ? moment
                                                            .unix(ele.start)
                                                            .local()
                                                            .format("D MMM YYYY")
                                                        : ""}{" "}
                                                    -{" "}
                                                    {ele?.end
                                                        ? moment
                                                            .unix(ele?.end)
                                                            .local()
                                                            .format("D MMM YYYY")
                                                        : ""}{" "}
                                                    {subjectDuration(ele?.end, ele?.start)}
                                                </p>
                                            )}
                                        </div> */}

                                                <div className="course-description" style={{ margin: "10px 0" }} title={new DOMParser().parseFromString(ele.item_description,"text/html").body.textContent || "NA"}>
                                                    {ele?.item_description
                                                        ? ele.item_description !== ""
                                                            ? TrimText(
                                                                new DOMParser().parseFromString(ele.item_description, "text/html").body.textContent.replace(/\s+/g, ' ').trim() || "NA",
                                                                150
                                                            )
                                                            : "NA"
                                                        : "NA"}
                                                </div>
                                                <div className="d-flex flex-column align-items-start justify-content-start">
                                                    <div className="progress-program progress-statistics">
                                                        <div className="program-stats w-100">
                                                            <div className="program-card-progress p-0" >
                                                                <span className="status" title="progress">
                                                                    {ele?.completedPercentage || "0"}% completed</span>
                                                            </div>
                                                            <div className="progress progress__assembly__card">
                                                                <div
                                                                    className="progress-bar zero-progress"
                                                                    role="progressbar"
                                                                    style={{
                                                                        width: (ele?.completedPercentage || 5) + "%",
                                                                    }}
                                                                    aria-valuenow="25"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                    title="progress"
                                                                >
                                                                    {ele?.completedPercentage}%
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="program-statistics-count-2" >
                                                        <ul className="num-info">
                                                            {ele?.assembliesTotalVideos > 0 && (
                                                                <li title="Videos" className="videoes">
                                                                    <i className="fal fa-video" />
                                                                    <span className="value">
                                                                        {ele?.assembliesTotalVideos}
                                                                    </span>
                                                                </li>
                                                            )}
                                                            {ele?.assembliesTotalQuiz > 0 && (
                                                                <li title="Quizzes" className="Quizzes">
                                                                    <i className="fal fa-clipboard-list" />
                                                                    <span className="value">
                                                                        {ele?.assembliesTotalQuiz}
                                                                    </span>
                                                                </li>
                                                            )}
                                                            {ele?.assembliesTotalDocuments > 0 && (
                                                                <li title="Files" className="files">
                                                                    <i className="fal fa-file" />
                                                                    <span className="value">
                                                                        {ele?.assembliesTotalDocuments}
                                                                    </span>
                                                                </li>
                                                            )}
                                                            {ele.progress?.total_assessment > 0 && (
                                                                <li
                                                                    title="Assessment"
                                                                    className="files"
                                                                >
                                                                    <i className="fal fa-ballot" />
                                                                    <span className="value">
                                                                        {ele.progress?.total_assessment}
                                                                    </span>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>) : !loading &&
            <div
                className="learning-material-listing no__content__section"
                id="accordion"
                role="tablist"
                aria-multiselectable="true"
            >
                <div className="subject-header p-0 " >
                    <div className="subject-sub-header p-0">
                        <h3>No Content Available.</h3>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default AssemblyItemBox
