

import React, { useRef, useEffect, useState } from 'react';
import SignaturePad from 'signature_pad';
import { base64ToFile } from '../../helper';

const SignaturePadComponent = ({signaturePadRef, formik, setShowIp, showIp}) => {
    const canvasRef = useRef(null);
    const [isEmpty, setIsEmpty] = useState(true);
    const [erasorDisplay, setErasorDisplay] = useState(true);
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [tool, setTool] = useState('pencil');
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        const canvas = canvasRef.current;
        const signaturePad = new SignaturePad(canvas, {
            backgroundColor: 'rgb(255, 255, 255)',
            penColor: 'rgb(0, 0, 0)'
        });
        signaturePadRef.current = signaturePad;

        const checkEmpty = () => {
            const currentData = signaturePad.toData();
            setUndoStack(currentData);
            setRedoStack([]); // Clear redo stack when new stroke is added
            setIsEmpty(signaturePad.isEmpty());
        };
        signaturePad.addEventListener('endStroke', checkEmpty);

        return () => {
            signaturePad.removeEventListener('endStroke', checkEmpty);
            signaturePad.clear();
        };
    }, []);

    // Modified effect to handle disabled state while keeping cancel button clickable
    useEffect(() => {
        if (signaturePadRef.current) {
            if (isDisabled) {
                signaturePadRef.current.off();
                const canvas = canvasRef.current;
                const overlay = document.createElement('div');
                overlay.style.position = 'absolute';
                overlay.style.top = '0';
                overlay.style.left = '0';
                overlay.style.right = '40px'; // Leave space for the cancel button
                overlay.style.bottom = '40px'; // Leave space for the bottom area
                overlay.style.cursor = 'not-allowed';
                overlay.style.zIndex = '1000';
                overlay.className = 'signature-overlay';
                canvas.parentElement.style.position = 'relative';
                canvas.parentElement.appendChild(overlay);

                return () => {
                    const existingOverlay = canvas.parentElement.querySelector('.signature-overlay');
                    if (existingOverlay) {
                        canvas.parentElement.removeChild(existingOverlay);
                    }
                };
            } else {
                signaturePadRef.current.on();
                const canvas = canvasRef.current;
                const existingOverlay = canvas.parentElement.querySelector('.signature-overlay');
                if (existingOverlay) {
                    canvas.parentElement.removeChild(existingOverlay);
                }
            }
        }
    }, [isDisabled]);

    const clearSignature = () => {
        signaturePadRef.current.clear();
        setIsEmpty(true);
        setUndoStack([]);
        setRedoStack([]);
    };

    const saveSignature = () => {
        if (signaturePadRef.current.isEmpty()) {
            alert("Please provide a signature first.");
        } else {
            const dataURL = signaturePadRef.current.toDataURL();
            const binary = base64ToFile(dataURL);
            setShowIp(true);
            setIsDisabled(true);
            formik.setFieldValue('student_signature', binary);
        }
    };

    const handleCancel = () => {
        setShowIp(false);
        setIsDisabled(false);
        formik.setFieldValue('student_signature', null);
    };

    const erase = () => {
        signaturePadRef.current.penColor = 'rgb(255, 255, 255)';
        setErasorDisplay(false);
        setTool('eraser');
    };

    const draw = () => {
        signaturePadRef.current.penColor = 'rgb(0, 0, 0)';
        setErasorDisplay(true);
        setTool('pencil');
    };

    const undo = () => {
        setShowIp(false);
        const data = signaturePadRef.current.toData();
        if (data.length > 0) {
            const lastStroke = data[data.length - 1];
            const newData = data.slice(0, -1);
            
            signaturePadRef.current.clear();
            signaturePadRef.current.fromData(newData);
            
            setUndoStack(newData);
            setRedoStack(prevStack => [...prevStack, lastStroke]);
            setIsEmpty(signaturePadRef.current.isEmpty());
        }
    };

    const redo = () => {
        if (redoStack.length > 0) {
            const strokeToRedo = redoStack[redoStack.length - 1];
            const currentData = signaturePadRef.current.toData();
            const newData = [...currentData, strokeToRedo];
            
            signaturePadRef.current.clear();
            signaturePadRef.current.fromData(newData);
            
            setUndoStack(newData);
            setRedoStack(prevStack => prevStack.slice(0, -1));
            setIsEmpty(false);
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (isDisabled) {
            canvas.style.cursor = 'not-allowed';
        } else if (tool === 'pencil') {
            canvas.style.cursor = 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'currentColor\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3E%3Cline x1=\'18\' y1=\'2\' x2=\'22\' y2=\'6\'%3E%3C/line%3E%3Cpath d=\'M7.5 20.5 19 9l-4-4L3.5 16.5 2 22z\'%3E%3C/path%3E%3C/svg%3E") 0 24, auto';
        } else if (tool === 'eraser') {
            canvas.style.cursor = 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'currentColor\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3E%3Cpath d=\'m7 21-4.3-4.3c-1-1-1-2.5 0-3.4l9.6-9.6c1-1 2.5-1 3.4 0l5.6 5.6c1 1 1 2.5 0 3.4L13 21\'%3E%3C/path%3E%3Cpath d=\'M22 21H7\'%3E%3C/path%3E%3Cpath d=\'m5 11 9 9\'%3E%3C/path%3E%3C/svg%3E") 0 24, auto';
        }
    }, [tool, isDisabled]);

    return (
        <div className="max-w-md position-relative signature-pad-outer">
            <div className="px-30 border border-gray-300 rounded-lg overflow-hidden">
                <canvas
                    ref={canvasRef}
                    style={{position: 'relative', left: '20px'}}
                />
                <hr className='mt-0'/>
                <div className="my-2 left-0 right-0 text-center position-relative text-gray-400">
                    Sign Above {showIp ? 
                        <button 
                            type='button' 
                            className='position-absolute' 
                            style={{right: '10px', bottom: '1px', zIndex: '1001'}} 
                            onClick={handleCancel}
                        >
                            Cancel
                        </button> : 
                        <button 
                            type='button' 
                            className='position-absolute' 
                            style={{right: '10px', bottom: '1px'}} 
                            onClick={saveSignature}
                        >
                            OK
                        </button>
                    }
                </div>
                <div className={`position-absolute ${showIp ? 'd-none' : 'd-flex'}`} style={{top: '10px', right: '10px', gap: '10px'}}>
                    <i role='button' onClick={undo} className='fal fa-undo'></i>
                    <i role='button' onClick={redo} className='fal fa-redo'></i>
                    {erasorDisplay ? 
                        <i role='button' onClick={erase} className='fal fa-eraser'></i>
                        :
                        <i role='button' className='fal fa-pencil' onClick={draw}></i>   
                    }
                </div>
            </div>
        </div>
    );
};

export default SignaturePadComponent;