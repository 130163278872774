import React, { useEffect, useState, useMemo, useRef } from "react";
import $ from "jquery";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useRouteMatch } from "react-router-dom";
import Countdown from "react-countdown";
import AssignmentStatus from "../course/AssignmentStatus";
import AssignmentType from "../course/AssignmentType";
import Swal from "sweetalert2";
import { GetSubjectAssignments, getSubjectAssignmentsByFilter, GetAssignmentDetailByStatus, getAssignmentsFiles } from "../../services/QualificationService";
import moment from "moment-timezone";
import Str from "../common/Str";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import { useDispatch, useSelector } from "react-redux";
import { twoDecimalRound } from "../../utils/commonFunction";
import { NonMaxSuppressionV3 } from "@tensorflow/tfjs";
import { BASE_URL, DOMAIN_URL, STUDENT_FILE_DOWNLOAD, STUDENT_FILE_URI, zipURL } from "../../utils/Constants";
import JSZip, { file } from "jszip";
import { saveAs } from "file-saver"
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// moment.tz.setDefault("Africa/Johannesburg");



const ShowDuration = ({ data }) => (
  <div class="assessment-06"><p>{data.timeHour}h:{data.timeMin}m</p></div>
);

const ShowResult = ({ data }) => (
  data.grade == 1 ? (
    data.assessmentMarkPercentage == 0 ?
      (<div class="assessment-07">
        <div class="as-red-bg as-widget" data-toggle="tooltip" title={'Incomplete'} >
          <p className="oneline-ellipsis">
            <i class="fas fa-circle"></i>Incomplete
          </p>
        </div>
      </div>) :
      data.assessmentMarkPercentage > 49 ?
        (<div class="assessment-07">
          <div class="as-green-bg as-widget" data-toggle="tooltip" title={'Competent'} >
            <p className="oneline-ellipsis">
              <i class="fas fa-circle"></i>Competent
            </p>
          </div>
        </div>) :
        (<div class="assessment-07">
          <div class="as-orange-bg as-widget" data-toggle="tooltip" title={'Not Yet Competent'} >
            <p className="oneline-ellipsis">
              <i class="fas fa-circle"></i>Not Yet Competent
            </p>
          </div>
        </div>)

    // ) : (`${Number(data.assessmentMarkPercentage).toFixed(2)}%`)
  ) : (data?.grade == 3 ? `${data?.assCalculateGradeStatus?.gradeText != null ? data?.assCalculateGradeStatus?.gradeText : 0} / ${data?.subAssTotMark != null ? data?.subAssTotMark : 0}` : (data?.grade == 2 ? data?.assCalculateGradeStatus?.gradeText : (`${twoDecimalRound(data?.assCalculateGradeStatus?.grade)}%`)))
);

const SubjectAssignmentsLatest = ({ subjectId, parentCall, shortCourse, subjectContent }) => {
  const [loading, setLoading] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [noDataComponent, setNoDataComponent] = useState([]);
  const [renderTable, setRenderTable] = useState(false);
  const [seeMore, setSeeMore] = useState([]);
  const [files, setFiles] = useState([]);
  const [isFiles, setIsFIles]=useState(false)
  const [tab, setTab] = useState("assessments")
  const [checkTotal, setCheckTotal] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [total, setTotal] = useState(0);
  const [percent, setPercent] = useState(0);
  const [checkPending, setCheckPending] = useState(0);
  const selectorState = useSelector((state) => state);
  const { user, isWithHeld } = selectorState
  const isWithHeldStatus = localStorage.getItem("resultWithHeld");
  const [filteredAssignments, setFilteredAssignments] = useState([]);
  const [fileName, setFileName] = useState('');
  const dispatch = useDispatch();
  const isAssessmentDownloadBtn = useSelector(state => state.learningMaterial.isAssessmentDownloadBtn)
  const {sid}=useParams()

  const AppealButton = ({ appeal_button, row_id }) => {
    switch (appeal_button) {
      case "appeal":
        return (
          <>
            <button
              title="Appeal"
              onClick={() => viewMe(row_id, "view_appeal")}
              className="btn rounded-circle btn-danger appeal-icon-circle"
            >
              <i class="fal fa-book-reader"></i>
              {/* <i className="fal fa-star"></i> Appeal */}
            </button>
          </>
        );
        break;
      case "appeal_view":
        return (
          <>
            <button
              title="View Appeal"
              onClick={() => viewMe(row_id, "view_appeal")}
              className="btn rounded-circle btn-warning"
            >
              <i class="fal fa-book-reader"></i>
              {/* <i className="fal fa-star"></i> View Appeal */}
            </button>
          </>
        );
        break;
      case "appeal_submit":
        return (
          <>
            <button
              title="Appeal Submitted"
              onClick={() => viewMe(row_id, "view_appeal")}
              className="btn rounded-circle btn-gray"
              disabled
            >
              <i class="fal fa-book-reader"></i>
              {/* <i className="fa fa-star"></i> Appeal Submitted */}
            </button>
          </>
        );
        break;
      default:
        return (
          <>
            <button
              title="No appeal options"
              onClick={() => { }}
              className="btn rounded-circle btn-gray"
              disabled
            >
              <i class="fal fa-book-reader"></i>
            </button>
          </>
        );
        break;
    }
  };

  const handleSeeMore = (row) => {
    let tempSeeMore = seeMore
    if (seeMore.includes(row.id)) {
      tempSeeMore.splice(tempSeeMore.indexOf(row.id), 1)
    } else {
      tempSeeMore.push(row.id)
    }
    setSeeMore([...tempSeeMore])
  }


  const columns = useMemo(() => [
    {
      name: "Type",
      selector: "ass_type",
      sortable: true,
      // minWidth: "130px",
      // maxWidth: "130px",
      cell: (row) => <AssignmentType ass_type={row.ass_type} isStudentAssest={row?.isStudentAssessed} />,
    },
    {
      name: "Name",
      selector: "name",
      wrap: true,
      sortable: true,
      // minWidth: "80px",
      // maxWidth: "180px",
      cell: (row) => (
        <div data-toggle="tooltip" title={row.name} className="assessment-02">
          <p onClick={() => viewMe(row.id, "view")} class="info-text">
            <a className="curser overflow-ellipsis2 plink">{row.name}</a>
          </p>
        </div>
      ),
    },
    {
      name: "Status",
      sortable: true,
      selector: "status",
      // minWidth: "170px",
      // maxWidth: "180px",
      /*center: true,*/
      cell: (row) => <AssignmentStatus status={row.status} />,

    },
    {
      name: <div class="thead-conditions">Conditions <i data-toggle="tooltip" title="This column communicates the nature of an exam/Assessment and the conditions associated with it" class="fal fa-info-circle"></i></div>,
      selector: "conditions",
      // minWidth: "180px",
      cell: (row) => (
        <div class="table-exam-act">
          <span class="ts-info-act">
            {row.camera ? <i data-toggle="tooltip" title="This exam requires your computer/Laptop to have an operational webcam" class="fal fa-webcam active"></i> : null}
            {row.audio ? <i data-toggle="tooltip" title="This exam requires your computer/Laptop to have an operational microphone" class="fal fa-microphone"></i> : null}
            {row.screen_share ? <i data-toggle="tooltip" title="This exam will record the contents of your screen, please have one screen and disconnect secondary screens" class="fal fa-desktop"></i> : null}
            {row.timeLimitActive ? <i data-toggle="tooltip" title="This is a timed exam" class="fal fa-clock"></i> : null}
            {/* <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="right" title={(row.camera || row.audio || row.screen_share) ? "This assignment has conditions" : "This exam will have no conditions"}><i class="fal fa-info-circle"></i></button> */}
          </span>
        </div>
      ),
      // button: true,
    },
    {
      name: "Open Date",
      selector: "open_date_time",
      sortable: true,
      omit: shortCourse,
      // minWidth: "160px",
      // maxWidth: "170px",
      cell: (row) => (
        <div class="assessment-04 dateTime">
          <p className="right-space">{moment.unix(row.open_date_time).format("D MMM 'YYYY")}</p><p>{moment.unix(row.open_date_time).format("HH:mm")}</p>
          {/* <p className="right-space">{row?.formatted_open_date_time ? row?.formatted_open_date_time : "-"}</p> */}
        </div>
      ),
    },
    {
      name: "Close Date",
      sortable: true,
      selector: "close_date_time",
      wrap: true,
      omit: shortCourse,
      // minWidth: "160px",
      // maxWidth: "170px",
      cell: (row) => (
        <div class="assessment-05 dateTime">
          <p className="right-space">{moment.unix(row.close_date_time).format("DD MMM 'YYYY")}</p><p>{moment.unix(row.close_date_time).format("HH:mm")}</p>
          {/* <p className="right-space">{row?.formatted_close_date_time ? row?.formatted_close_date_time : "-"}</p> */}
        </div>
      ),
    },
    {
      name: "Duration",
      selector: "timeHour",
      // minWidth: "80px",
      // maxWidth: "100px",
      sortable: true,
      cell: (row) =>
        row.timeLimitActive == 1 ? <ShowDuration data={row} /> : "N/A",
    },
    {
      name: "Result",
      selector: "grade",
      sortable: false,
      // minWidth: "140px",
      // maxWidth: "100%",
      cell: (row) => {
        return (row.ass_type != "Survey" ?
          (row.status === "Closed-Submitted" ? "-" : (row.status == "Closed-Marked" || row.status == "Closed Not-Submitted") && user.status != "Results Witheld" && isWithHeldStatus != "true" && row.notify_assessed == 1 ? ((subjectContent?.grading_standard == 2) ? (<>
            {(row?.grademixCulc || []).map((item, index) => {
              if (index > 2 && !seeMore.includes(row.id)) {
                return ""
              }
              return <div>{item.sor_name}: <strong>{item?.grading_type == 3 ? `${item?.gradeText}/${item?.ass_total_mark}` : item.gradeText}</strong></div>
            })}
            {row?.grademixCulc?.length && (row?.grademixCulc?.length > 3) ? <span className="table-see-more" onClick={() => handleSeeMore(row)}>See {seeMore.includes(row.id) ? "Less" : "More"}...</span> : ""}
          </>) : <ShowResult data={row} />) : user.status == "Results Witheld" && isWithHeldStatus == "true" ? <span className="witheld-status">WITHHELD</span> : "-")
          : "-")
      }
    },
    {
      name: "Action",
      // minWidth: "120px",
      cell: (row) => (
        <div class="assessment-08 d-flex">
          <div class="as-buttons">
            <button
              title="Open"
              class="btn btn-primary rounded-circle"
              onClick={() => viewMe(row.id, "view")}
            >
              <i class="fal fa-folder-open"></i>
            </button>
          </div>
          {shortCourse ? null : <div class="as-buttons">
            <AppealButton appeal_button={row.appeal_button} row_id={row.id} />
          </div>}

        </div>
      ),
      // button: true,
    },
  ]);

  const [status, setStatus] = useState([])
  const [searchStatusCheck, setSearchStatusCheck] = useState({
    "action8": false,
    "action9": false,
    "action10": false,
    "action11": false,
    "action12": false,
    "action13": false,
    "action14": false,
  })
  const [searchType, setSearchType] = useState([])
  const [searchTypeCheck, setSearchTypeCheck] = useState({
    "action1": false,
    "action2": false,
    "action3": false,
    "action4": false,
    "action5": false,
    "action6": false,
    "action7": false,
    "action9": false,
    "action10": false,
    "action15": false,
    "action16": false,
    "action17": false
  })
  const [search, setSearch] = useState("");
  //cleaning assessment file object
  useEffect(()=>{
    setFiles([])
    setFiles(false)
  },[])

  const dataToRender = () => {
    let updatedData = []
    let allData = filteredAssignments
    if (search.length) {
      let tempName = allData.filter(item => {
        let includes = item.name.toLowerCase().includes(search.toLowerCase())
        if (includes) {
          return includes
        } else return null
      })
      let tempType = allData.filter(item => {
        let includes = item.ass_type && item.ass_type.toLowerCase().includes(search.toLowerCase())
        if (includes) {
          return includes
        } else return null
      })
      let tempStatus = allData.filter(item => {
        let includes = item.status && item.status.toLowerCase().includes(search.toLowerCase())
        if (includes) {
          return includes
        } else return null
      })
      let tempOpenDate = allData.filter(item => {
        let includes = item.open_date_time && (moment.unix(item.open_date_time).format("D MMM 'YY HH:mm a").toLowerCase().includes(search.toLowerCase()))
        if (includes) {
          return includes
        } else return null
      })
      let tempCloseDate = allData.filter(item => {
        let includes = item.close_date_time && (moment.unix(item.close_date_time).format("D MMM 'YY HH:mm a").toLowerCase().includes(search.toLowerCase()))
        if (includes) {
          return includes
        } else return null
      })

      let data = [...tempName, ...tempType, ...tempStatus, ...tempOpenDate, ...tempCloseDate]
      let unique = [...new Set(data)]
      // console.log('the values of inclues and statusSearch is ', tempName, tempType, tempStatus)

      updatedData = unique
    } else {
      updatedData = allData
    }

    if (searchType.length) {
      let tempStatus = updatedData
      let tempSearch = tempStatus.filter(item => {
        let tempItem = item.ass_type ? item.ass_type.toLowerCase() : ''
        var tempItemNew = tempItem ? tempItem.replace(/-/g, ' ') : tempItem;
        const startsWith = searchType.find(function (post, index) {
          let tempPost = post ? post.toLowerCase() : ''
          var tempPostNew = tempPost ? tempPost.replace(/-/g, ' ') : tempPost;
          if (tempPostNew == tempItemNew)
            return item;
        });
        if (startsWith) {
          return startsWith
        } else return null
      })
      updatedData = tempSearch
    }

    if (status.length) {
      let tempStatus = updatedData
      let tempSearch = tempStatus.filter(item => {
        let tempItem = item.status ? item.status.toLowerCase() : ''
        var tempItemNew = tempItem ? tempItem.replace(/-/g, ' ') : tempItem;
        const startsWith = status.find(function (post, index) {
          let tempPost = post ? post.toLowerCase() : post
          var tempPostNew = tempPost ? tempPost.replace(/-/g, ' ') : tempPost;
          if (tempPostNew == tempItemNew)
            return item;
        });
        if (startsWith) {
          return startsWith
        } else return null
      })
      updatedData = tempSearch
    }
    if (status.length || searchType.length || search.length) {
      // console.log(updatedData)
      return updatedData
    } else {
      return filteredAssignments
    }
  }

  // ** Function to handle status filter  
  const handleStatusFilter = e => {
    const value = e.target.value
    let arr = status;

    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setStatus(arr)
    let id = e.target.id;
    setSearchStatusCheck({ ...searchStatusCheck, [id]: !searchStatusCheck[id] });
  }

  // useEffect(() => {
  //   if (tab == "upcoming") {
  //     setStatus(["Pending"]);
  //     setSearchStatusCheck({
  //        ...searchStatusCheck,
  //        action12 : true,
  //        action10 : false,
  //        action9 : false
  //       });
  //   } else if(tab == "past"){
  //     setStatus(["Closed-Submitted"]);
  //     setSearchStatusCheck({
  //       ...searchStatusCheck,
  //       action10 : true,
  //       action12 : false,
  //       action9 : false
  //     });
  //   }  else if(tab == "marked"){
  //     setStatus(["Closed-Marked"]);
  //     setSearchStatusCheck({ 
  //     ...searchStatusCheck, 
  //     action9 : true,
  //     action10 : false,
  //     action12 : false
  //   });
  //   } else {
  //     setStatus([]);
  //     setSearchStatusCheck({
  //       action8: false,
  //       action9: false,
  //       action10: false,
  //       action11: false,
  //       action12: false,
  //       action13: false,
  //       action14: false,
  //     });
  //   }
  // }, [tab]);

  useEffect(() => {
    const todayStart = moment().startOf("day"); // Start of today (midnight)
    const todayEnd = moment().endOf("day"); // End of today (11:59:59 PM)
  
    let filteredAssignments;

    switch (tab) {
      case "upcoming":
        filteredAssignments = assignments.filter(
          (item) =>
            item.status !== "Closed Not-Submitted" && // Exclude "Closed Not-Submitted" items
            moment(item.close_date_time * 1000).isSameOrAfter(moment().startOf('day')) // Compare close time with the start of today
        );
        break;

      case "past":
        filteredAssignments = assignments.filter(
          (item) =>
            item.status === "Closed Not-Submitted" && // Include only "Closed Not-Submitted" items
          moment(item.close_date_time * 1000).isBefore(moment())// Compare close time with the start of today
        );
        break;

      case "marked":
        filteredAssignments = assignments.filter(
          (item) => item.notify_assessed === 1
        );
        break;

      default:
        filteredAssignments = assignments; // Default to showing all assignments
        break;
    }
  
    setFilteredAssignments(filteredAssignments);
  }, [tab, assignments]);
  
  useEffect(() => {
    setFilteredAssignments(assignments)
  }, [])

  // ** Function to handle type filter  
  const handleTypeFilter = e => {
    const value = e.target.value
    let arr = searchType;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setSearchType(arr);
    let id = e.target.id;
    setSearchTypeCheck({ ...searchTypeCheck, [id]: !searchTypeCheck[id] });
  }

  // ** Function to handle type filter  
  const handleSearchFilter = e => {
    const value = e.target.value;
    setSearch(value);
  }

  const handleAttachment=()=> {
    getAssignmentsFiles({ sid: subjectId })
      .then((res) => {
        if (res.status == 200) {
          setFiles(res.data?.AssignmentsFiles)
          setFileName(res.data?.courseName)
          setIsFIles(true)
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Download Failed",
          text: error.message,
        });
      });
  }

  useEffect(() => {
    const downloadFile = async (url) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
  
        xhr.onload = function() {
          if (this.status === 200) {
            resolve(xhr.response);
          } else {
            reject(new Error(`Download failed with status ${this.status}`));
          }
        };
  
        xhr.onerror = function() {
          reject(new Error('Network error occurred'));
        };
  
        xhr.send();
      });
    };
  
    const downloadAllFiles = async () => {
      const zip = new JSZip();
      const newArr = files || [];
  
      if (newArr.length) {
        Swal.fire({
          title: "Creating ZIP",
          text: "Please wait while we create the ZIP file...",
          icon: "info",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
      }
  
      try {
        await Promise.all(
          newArr.map(async (data) => {
            if (data?.submission_files?.length || data?.feedback_files?.length) {
              const assignmentFolder = zip.folder(data?.assignmentsName.replace(/\//g, ' '));
              let hasFiles = false;
              // Download Submission Files
              if (data?.submission_files?.length) {
                let submissionFolder = assignmentFolder?.folder("Submission Files");
                let submissionHasFiles = false;
                await Promise.all(
                  data?.submission_files.map(async (file) => {
                   
                    const fileURL = file?.relativePath?.startsWith("http")
                      ? file?.relativePath
                      : `${zipURL}${BASE_URL.replace("https://","")}/${file?.relativePath
                          .replaceAll("/home/myaie/public_html/", "")
                          .replace("public/", "")
                          .replace("null/", "")}`;
                    
                    const fileExt = file?.relativePath?.split("/").reverse()[0];
  
                    try {
                      const blob = await downloadFile(fileURL);
                      submissionFolder.file(fileExt, blob, { binary: true });
                      submissionHasFiles = true; 
                      hasFiles = true;
                    } catch (err) {
                      console.error(`Error downloading submission file: ${fileExt}`, err);
                    }
                  })
                );
                if (!submissionHasFiles) {
                  zip.remove(`${data?.assignmentsName.replace(/\//g, ' ')}/Submission Files`); 
                }
              }
  
             // Download feedback Files
              if (data?.feedback_files?.length) {
                let feedbackFolder = assignmentFolder.folder("Feedback Files");
                let feedbackHasFiles = false;
                await Promise.all(
                  data?.feedback_files?.map(async (file) => {
                    const fileURL = file?.relativePath?.startsWith("http")
                      ? file?.relativePath
                      : `${zipURL}${BASE_URL.replace("https://","")}/${file?.relativePath
                        .replaceAll("/home/myaie/public_html/", "")
                          .replace("public/", "")
                          .replace("null/", "")}`;
                    
                    const fileExt = file?.relativePath?.split("/").reverse()[0];
  
                    try {
                      const blob = await downloadFile(fileURL);
                      feedbackFolder.file(fileExt, blob, { binary: true });
                      feedbackHasFiles = true;
                      hasFiles = true;
                    } catch (err) {
                      console.error(`Error downloading submission file: ${fileExt}`, err);
                    }
                  })
                );
                if (!feedbackHasFiles) {
                  zip.remove(`${data?.assignmentsName.replace(/\//g, ' ')}/Feedback Files`); // Prevent folder creation if no valid files
                }
              }
              if (!hasFiles) {
                zip.remove(data?.assignmentsName); 
              }
            }
          })
        );

        // Check if the ZIP is empty
        if (Object.keys(zip.files).length === 0) {
          Swal.fire({
            icon: "error",
            title: "No valid files to download",
            text: "All files are invalid or missing.",
          });
          return; // Exit without creating a ZIP
        }
  
        // Generate and save ZIP
        const content = await zip.generateAsync({ type: "blob" });
        saveAs(content, `${fileName}.zip`);
        setIsFIles(false)
        Swal.fire({
          icon: "success",
          title: "Downloading Finished",
          confirmButtonText: "OK",
        });
  
      } catch (err) {
        setIsFIles(false)
        console.error("Error processing attachments:", err);
        Swal.fire({
          icon: "error",
          title: "Download Failed",
          text: err.message,
        });
      }
    };
  
    if (isFiles) {
      if (files?.length > 0) {
        downloadAllFiles();
      } else {
        setIsFIles(false)
        Swal.fire({
          icon: "error",
          title: "No assignments to download.",
        });
      }
    }
  }, [files, isFiles]);

  useEffect(() => {
    setLoading(true)
    let mounted = true;
    GetSubjectAssignments({ sid: subjectId })
      .then((res) => {
        if (mounted) {
          const finalData = res.data.map(item => {
            if (item.status == "Closed-Marked" && item.notify_assessed == 0) {
              item.status = "Closed-Submitted";
            }
            return item;
          })

          const allFiles = finalData.reduce((acc, attachment) => {
            if (attachment?.Files && Array.isArray(attachment.Files)) {
              return [...acc, ...attachment.Files];
            }
            return acc;
          }, []);
          setFiles(allFiles);
          setAssignments(finalData);
        }
        if (res && res.data && res.data.length == 0) {
          setNoDataComponent(Str.noRecord)
        }
        setRenderTable(true)
      })
      .catch((error) => { });
    setTimeout(() => {
      setLoading(false)
    }, 3000);
    return function cleanup() {
      mounted = false;
    };
  }, [subjectId]);

  const viewMe = (id, view) => {
    parentCall(id, view);
  };

  const resetFilter = () => {
    setSearchType([]);
    setSearchTypeCheck({
      "action1": false,
      "action2": false,
      "action3": false,
      "action4": false,
      "action5": false,
      "action6": false,
      "action7": false,
      "action9": false,
      "action10": false,
      "action15": false,
      "action16": false,
      "action17": false
    });
    setStatus([]);
    setSearchStatusCheck({
      "action8": false,
      "action9": false,
      "action10": false,
      "action11": false,
      "action12": false,
      "action13": false,
      "action14": false,
    });
    setSearch('')
  };

  return (
    <>
      <div className="custom-table-div custom-table-div2 filter-search-icon card card-table-custom">

        <div className="top-bar-head-sec assessment__container__main">
          <div className="d-flex align-items-center assessment__head__container">
            <div className="assessment-table-heading">
              Your Assessments
            </div>
            <div className="lecture-tabs-wrap assessment__tab__container">
              <ul className="nav nav-pills course-nav-sticky assessment__filter__tabs" id="pills-tab" role="tablist" style={{ border: "none", marginLeft: "25px" }}>
                <li className="nav-item " title="Overview" onClick={() => setTab("upcoming")}>
                  <span className={`nav-link assessment-tab-item ${tab == "upcoming" ? "active" : ""}`}>
                    <i className="fal fa-calendar-alt"></i>
                    Upcoming
                  </span>
                </li>

                <li className="nav-item" title="Overview" onClick={() => setTab("past")}>
                  <span className={`nav-link assessment-tab-item ${tab == "past" ? "active" : ""}`}>
                    <i className="fal fa-history"></i>
                    Past
                  </span>
                </li>

                <li className="nav-item" title="Overview" onClick={() => setTab("marked")}>
                  <span className={`nav-link assessment-tab-item ${tab == "marked" ? "active" : ""}`}>
                    <i className="fal fa-clipboard-check"></i>
                    Marked
                  </span>
                </li>

                <li className="nav-item" title="Overview" onClick={() => setTab("assessments")}>
                  <span className={`nav-link assessment-tab-item ${tab == "assessments" ? "active" : ""}`}>
                    <i className="fal fa-ballot-check"></i>
                    All Assessments
                  </span>
                </li>
              </ul>
            </div>
           {isAssessmentDownloadBtn ?<div className="download__assesment__section">
            <button
              title="Download All"
              className={`btn btn-white-bordered download-assess`}
              onClick={() => { handleAttachment() }}
              // data-toggle="modal"
              // data-target="#downloadAssessmentAll"
            >
              <i className="fal fa-arrow-to-bottom"></i>Download All
            </button>
          </div>: null} 
          </div>
          <div className="data-table-extensions assessment__filter__container p-0" style={{gap:"50px"}}>
            <div className="data-table-extensions-filter" style={{ top: "auto", display: "flex", }}>
              <label htmlFor="filterDataTable" className="icon"></label>
              <input
                type="text"
                name="filterDataTable"
                className="filter-text"
                placeholder="Search"
                onChange={handleSearchFilter}
                value={search}
              />
              <div className="filter-eff filter-data-btn">
                <button className="filter-buttons">
                  <i className="fal fa-filter"></i>
                </button>
              </div>
            </div>
            {/* <div className="data-table-extensions-action"> */}
            <div className="filter-button-group assessment__filter__group m-0 ">
              <div className="filter-eff filter-data-btn">
                <button className="filter-buttons">
                  <i className="fal fa-filter"></i>
                </button>
              </div>
              <div className="assessment-table-filter">
                <div className="btn-type-filter dropdown-comman">
                  <div className="dropdown multiselect">
                    <button className={`btn btn-default dropdown-toggle ${searchType.length ? 'btn-selected' : ''}`} type="button"
                      id="typeBtnId" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" data-bs-toggle="dropdown"
                      data-bs-auto-close="false" title="Type">
                      <span>
                        Type <i className="fal fa-angle-down"></i>
                      </span>
                    </button>
                    <form className="dropdown-menu remove-list-style">
                      <ul aria-labelledby="typeBtnId">
                        <li className="item-text-blue">
                          <input type="checkbox" id="action1" onClick={handleTypeFilter} value="Assignment"
                            checked={searchTypeCheck.action1} />
                          <label htmlFor="action1">
                            <i className="fal fa-file"></i> Assignment
                          </label>
                        </li>
                        <li className="item-green-blue">
                          <input type="checkbox" id="action22" onClick={handleTypeFilter} value="Practical Assessment"
                            checked={searchTypeCheck.action22} />
                          <label htmlFor="action22">
                            <i className="fal fa-ballot-check"></i> Practical Assessment
                          </label>
                        </li>
                        <li className="item-dark-green-blue">
                          <input type="checkbox" id="action21" onClick={handleTypeFilter} value="Practical Attempt"
                            checked={searchTypeCheck.action21} />
                          <label htmlFor="action21">
                            <i className="fal fa-ballot-check"></i> Practical Attempt
                          </label>
                        </li>
                        <li className="item-text-green">
                          <input type="checkbox" id="action2" onClick={handleTypeFilter} value="Internal Exam"
                            checked={searchTypeCheck.action2} />
                          <label htmlFor="action2">
                            <i className="fal fa-file"></i> Internal Exam
                          </label>
                        </li>
                        <li className="item-text-yellow">
                          <input type="checkbox" id="action3" onClick={handleTypeFilter} value="External Exam"
                            checked={searchTypeCheck.action3} />
                          <label htmlFor="action3">
                            <i className="fal fa-university"></i> External Exam
                          </label>
                        </li>
                        <li className="item-text-purple">
                          <input type="checkbox" id="action4" onClick={handleTypeFilter} value="Test"
                            checked={searchTypeCheck.action4} />
                          <label htmlFor="action4">
                            <i className="fal fa-file"></i> Test
                          </label>
                        </li>
                        <li className="item-text-blue">
                          <input type="checkbox" id="action5" onClick={handleTypeFilter} value="PoE-Formative"
                            checked={searchTypeCheck.action5} />
                          <label htmlFor="action5">
                            <i className="fal fa-folder-tree"></i> PoE-Formative
                          </label>
                        </li>
                        <li className="item-text-green">
                          <input type="checkbox" id="action6" onClick={handleTypeFilter} value="PoE-Summative"
                            checked={searchTypeCheck.action6} />
                          <label htmlFor="action6">
                            <i className="fal fa-folder-tree"></i> PoE-Summative
                          </label>
                        </li>
                        <li className="item-text-purple">
                          <input type="checkbox" id="action7" onClick={handleTypeFilter} value="PoE-Informal Activities"
                            checked={searchTypeCheck.action7} />
                          <label htmlFor="action7">
                            <i className="fal fa-folder-tree"></i> PoE-Informal Activities
                          </label>
                        </li>
                        <li className="item-text-blue">
                          <input type="checkbox" id="action15" onClick={handleTypeFilter} value="Survey"
                            checked={searchTypeCheck.action15} />
                          <label htmlFor="action15">
                            <i className="fal fa-file"></i> Survey
                          </label>
                        </li>
                        <li className="item-text-blue">
                          <input type="checkbox" id="action16" onClick={handleTypeFilter} value="Sick Test"
                            checked={searchTypeCheck.action16} />
                          <label htmlFor="action16">
                            <i className="fal fa-file"></i> Sick Test
                          </label>
                        </li>
                        <li className="item-text-yellow">
                          <input type="checkbox" id="action18" onClick={handleTypeFilter} value="KM Exam"
                            checked={searchTypeCheck.action18} />
                          <label htmlFor="action18">
                            <i className="fal fa-file"></i> KM Exam
                          </label>
                        </li>

                        <li className="item-text-red">
                          <input type="checkbox" id="action19" onClick={handleTypeFilter} value="PM Exam"
                            checked={searchTypeCheck.action19} />
                          <label htmlFor="action19">
                            <i className="fal fa-file"></i> PM Exam
                          </label>
                        </li>

                        <li className="item-text-yellow">
                          <input type="checkbox" id="action20" onClick={handleTypeFilter} value="Special Exam"
                            checked={searchTypeCheck.action20} />
                          <label htmlFor="action20">
                            <i className="fal fa-file"></i> Special Exam
                          </label>
                        </li>

                        <li className="item-text-blue">
                          <input type="checkbox" id="action17" onClick={handleTypeFilter} value="Supplementary Exam"
                            checked={searchTypeCheck.action17} />
                          <label htmlFor="action17">
                            <i className="fal fa-file"></i> Supplementary Exam
                          </label>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>
              <div className="assessment-table-status">
                <div className="btn-type-Status dropdown-comman">
                  <div className="dropdown multiselect">
                    <button className={`btn btn-default dropdown-toggle ${status.length ? 'btn-selected' : ''}`} type="button"
                      id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" title="Status">
                      <span>
                        Status <i className="fal fa-angle-down"></i>
                      </span>
                    </button>
                    <form className="dropdown-menu remove-list-style">
                      <ul aria-labelledby="dropdownMenu1">
                        <li className="item-text-red">
                          <input type="checkbox" id="action8" value="Closed Not-Submitted" onClick={handleStatusFilter}
                            checked={searchStatusCheck.action8} />
                          <label htmlFor="action8">
                            <span className="as-red-bg as-widget">CLOSED NOT-SUBMITTED</span>
                          </label>
                        </li>
                        <li className="item-text-blue">
                          <input type="checkbox" id="action9" onClick={handleStatusFilter} value="Closed-Marked"
                            checked={searchStatusCheck.action9} />
                          <label htmlFor="action9">
                            <span className="as-blue-bg as-widget">CLOSED-MARKED</span>
                          </label>
                        </li>
                        <li className="item-text-yellow">
                          <input type="checkbox" id="action10" onClick={handleStatusFilter} value="Closed-Submitted"
                            checked={searchStatusCheck.action10} />
                          <label htmlFor="action10">
                            <span className="as-yellow-bg as-widget">CLOSED-SUBMITTED</span>
                          </label>
                        </li>
                        <li className="item-text-green">
                          <input type="checkbox" id="action11" onClick={handleStatusFilter} value="Submitted"
                            checked={searchStatusCheck.action11} />
                          <label htmlFor="action11">
                            <span className="as-green-bg as-widget">SUBMITTED</span>

                          </label>
                        </li>
                        <li className="item-text-yellow">
                          <input type="checkbox" id="action12" onClick={handleStatusFilter} value="Pending"
                            checked={searchStatusCheck.action12} />
                          <label htmlFor="action12">
                            <span className="as-yellow-bg as-widget">PENDING</span>

                          </label>
                        </li>
                        <li className="item-text-green">
                          <input type="checkbox" id="action13" onClick={handleStatusFilter} value="Draft"
                            checked={searchStatusCheck.action13} />
                          <label htmlFor="action13">
                            <span className="as-green-bg as-widget">DRAFT</span>
                          </label>
                        </li>
                        <li className="item-text-blue">
                          <input type="checkbox" id="action14" onClick={handleStatusFilter} value="Open"
                            checked={searchStatusCheck.action14} />
                          <label htmlFor="action14">
                            <span className="as-blue-bg as-widget">OPEN</span>
                          </label>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>
              <div className="button-reset">
                <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                  <i className="fal fa-redo"></i>Reset
                </button>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>

        {!renderTable && <SkeletonTicketList />}
        {renderTable &&
          <DataTable
            title="Assessments"
            columns={columns}
            data={dataToRender()}
            pagination={true}
            noDataComponent={noDataComponent}
            defaultSortField="close_date_time"
            defaultSortAsc={false}
          />
        }
      </div>
      

      {/* <div className="downloading-progress modal fade" id="downloadAssessmentAll" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="swal2-header">
                <div className="swal2-icon swal2-info swal2-icon-show d-flex">
                  <div className="swal2-icon-content">i</div>
                </div>
                <h2 className="swal2-title d-flex mb-4">Downloading...</h2>
                <div className="progress w-100 mb-2">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuenow={checkTotal === 1 ? percent : ((checkPending / checkTotal) * 100).toFixed(2)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width: checkTotal === 1 ? `${percent}%` : `${((checkPending / checkTotal) * 100).toFixed(2)}%`
                    }}
                  >
                    {checkTotal === 1 ? `${percent}%` : `${((checkPending / checkTotal) * 100).toFixed(2)}%`}
                  </div>
                </div>
                <>File Size : {loaded}/{total} Pending Files : {checkPending}/{checkTotal}</>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default SubjectAssignmentsLatest;
