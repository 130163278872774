import React, { useEffect, useState } from "react";
import ReReSubject from "./re_registration_sub/ReReSubject";

function ElectiveModuleForm(props) {
  const {
    formik,
    setparentSubjectReSubLength,
    parentSubjectReSubLength,
    setparentSubjectReSubLength1,
    parentSubjectReSubLength1,
    reRegSubject,
    progressionData,
    modulesData
  } = props;

  const [fields, setFields] = useState([{ subject: "" }]);
  const [fields1, setFields1] = useState([{ subject: "" }]);
  let formatted = []
  let obj = {}

  function handleAdd(key){
    const values = [...parentSubjectReSubLength[key]]
    values.push("");
    setFields(values);
    setparentSubjectReSubLength({
      ...parentSubjectReSubLength,
      [key]: values
    });
  }

  function handleRemove(i,flag=0,key){
    const values = [...parentSubjectReSubLength[key]]
      if (values.length > 1) {
        values.splice(i, 1);
        setFields(values);
        props.setparentSubjectReSubLength({
          ...parentSubjectReSubLength,
          [key] : values
        });
      }
  }
  

  function handleSubjectChange(i, event, key){
    const values = [...parentSubjectReSubLength[key]]
    values[i] = event.target.value;
    setFields(values);
    props.setparentSubjectReSubLength({
     ...parentSubjectReSubLength,
      [key] : values
    });
  }

  useEffect(() => {
    let updatedData = {}
    modulesData.map((d,i) => {
      updatedData[d.key] = [""]
    })
    props.setparentSubjectReSubLength(updatedData);
    
  },[])
  
  const getTotalCredits = (array) => {
    let totalCredits = 0;
    const totality = array.map((d,i) => {
      totalCredits += d.credits;
      if(i == array.length - 1) return ` (${totalCredits} Credits)`
    })
    return totality;
  }
  
  return (
    <>
            <h5 className="textdanger heading-5 mt-30"> Elective Modules</h5>
            {modulesData.map((d, moduleIdx) => (
            <div key={moduleIdx} className="mb-2 mt-3">
              <b>{d.name}</b> 
              {getTotalCredits(d.modules.modules)}
              <span>
                <b className="credits-outstanding ml-2">Credits Outstanding: {d.modules.credits_outstanding}</b>
              </span>
              {(formik.values.elective_arr[d.key] || [""]).map((item, idx) => (
                <div key={idx} className="row">
                  <div className="col-sm-6">
                    <select
                      className="form-control"
                      name={`subjectReSub_[${idx}].subject`}
                      onChange={(e) => handleSubjectChange(idx, e,d.key)}
                      onBlur={formik.handleBlur}
                      value={item}
                    >
                      <option value="">Select</option>
                      <ReReSubject reRegSubject={d.modules.modules} />
                    </select>

                    {formik.touched.subjectReSub_ &&
                    formik.errors.subjectReSub_ &&
                    formik.touched.subjectReSub_[idx] &&
                    formik.errors.subjectReSub_[idx] &&
                    formik.touched.subjectReSub_[idx].subject &&
                    formik.errors.subjectReSub_[idx].subject ? (
                      <div className="invalid-feedback" style={{ display: "block" }}>
                        {formik.errors.subjectReSub_[idx].subject}
                      </div>
                    ) : null}
                  </div>
                  <div className="col pl-0" title="Delete">
                    <a
                      href="#"
                      className="btn btn-danger"
                      onClick={() => handleRemove(idx,moduleIdx,d.key)}
                    >
                      <i className="fal fa-trash-alt"></i>
                    </a>
                  </div>
                </div>
              ))}
              {(formik.values.elective_arr[d.key]?.length != d.modules.modules.length) && <div className="form-group m-0">
                <a
                  href="#"
                  className="btn btn-link p-0 btn-additional"
                  onClick={() => handleAdd(d.key)}
                >
                  <i className="fal fa-plus"></i> Add additional line item
                </a>
              </div>}
            </div>
          ))}
      
    </>
  );
}

export default ElectiveModuleForm;
