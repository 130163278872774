import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearQuiz, setQuiz, setQuizComplete } from "../../../store/actions";
import { RenderAttachList } from "../../common/RenderAttachListFeed";
  function ReviewPracticalQuestion(props) {
    const {
        key,
        grpId,
        qInd,
        question,
        refreshprops,
        answer,
        setanswer,
        evidenceType,
        evidenceStatus,
        files,
        setFiles,
        setPhotoFiles,
        setVideoFiles,
        setDocFiles,
        photoFiles,
        videoFiles,
        docFiles,
        practicalAttachment
    }= props;
        // console.log(":::::::------answer-----:::::",answer);
        const dispatch = useDispatch();
        const [filesByQuestion, setFilesByQuestion] = useState({});
        const [openEvidence, setOpenEvidence] = useState({});
        const getCurrentQuestionFiles = (type) => {
            const questionId = grpId || question.qid;
            if (!filesByQuestion[questionId]) {
                return [];
            }
            return filesByQuestion[questionId][type] || [];
        };

        // useEffect(() => {
        //   setFiless(answer && answer?.length > 0 ? answer : []);
        // }, [answer]);

        const getFileName = (url) => {
            const name = url?.split("/").reverse()[0];
            return name;
        }               

        const formatFileSize = (sizeInBytes) => {
            if (sizeInBytes < 1024) {
                return `${sizeInBytes} bytes`;
            } else if (sizeInBytes < 1048576) {
                return `${(sizeInBytes / 1024).toFixed(2)} KB`;
            } else {
                return `${(sizeInBytes / 1048576).toFixed(2)} MB`;
            }
        };

        const renderEvidenceTypeItem = (type, evidence = false) => {
            switch (type?.toLowerCase().replace(/\s+/g, "_")) {
                case "camera":
                    return <span className="evidence__type__item"><i className="far fa-camera" />Photo Evidence {evidence ? <i className="far fa-check-circle item__green" /> : <i className="far fa-times-circle item__red" />}</span>;
                case "video":
                    return <span className="evidence__type__item"><i className="far fa-video" />Video Evidence {evidence ? <i className="far fa-check-circle item__green" /> : <i className="far fa-times-circle item__red" />}</span>;
                case "document":
                    return <span className="evidence__type__item"><i className="far fa-file-alt" />Document Evidence {evidence ? <i className="far fa-check-circle item__green" /> : <i className="far fa-times-circle item__red" />}</span>;
                default:
                    // return
                    return
            }
        }

        let evidenceList = [];
        if (question) {
            evidenceList.push({ key: 'evidence_photo', value: question.evidence_photo });
            evidenceList.push({ key: 'evidence_video', value: question.evidence_video });
            evidenceList.push({ key: 'evidence_document', value: question.evidence_document });
        }
        return (
            <div className={`${!question?.evidence_lecturer_observation ? 'padding__bottom__none' : ''} practical__assessment__questionList`}>
                {(question?.question_user_feedback) ? <div className="feedback__section">
                    <span className="feedback__section__title">Feedback</span>
                    <div className="feedback__section__content">
                        <p>{question?.question_user_feedback}</p>
                    </div>
                </div> : null}
                {evidenceList?.length > 0
                    ? evidenceList.map((item) =>
                        item?.value ? (
                            <div key={item.key} className={`profile-doc-upload-blk practical__assessment__box`}>
                                <div className="custom__accordion__box">
                                    <div className="accordion__head">
                                        <div className="head__bar review__box__head">
                                            <div
                                                className=""
                                                onClick={() => {
                                                    if (
                                                        (item.key === 'evidence_document' && docFiles[question.qid]?.length) ||
                                                        (item.key === 'evidence_photo' && photoFiles[question.qid]?.length) ||
                                                        (item.key === 'evidence_video' && videoFiles[question.qid]?.length)
                                                    ) {
                                                        setOpenEvidence((prev) => ({
                                                            ...prev,
                                                            [item.key]: !prev[item.key],
                                                        }));
                                                    }
                                                }}
                                            >
                                                {['evidence_document', 'evidence_photo', 'evidence_video'].includes(item.key) && item.value
                                                    ? renderEvidenceTypeItem(
                                                        item.key === 'evidence_document'
                                                            ? 'document'
                                                            : item.key === 'evidence_photo'
                                                                ? 'camera'
                                                                : 'video',
                                                        (item.key === 'evidence_document' && docFiles[question.qid]?.length) ||
                                                        (item.key === 'evidence_photo' && photoFiles[question.qid]?.length) ||
                                                        (item.key === 'evidence_video' && videoFiles[question.qid]?.length)
                                                    )
                                                : null}
                                            </div>
                                            {((item.key === 'evidence_document' && docFiles[question.qid]?.length) ||
                                                (item.key === 'evidence_photo' && photoFiles[question.qid]?.length) ||
                                                (item.key === 'evidence_video' && videoFiles[question.qid]?.length)) && <span
                                                type="button"
                                                onClick={() =>
                                                    setOpenEvidence((prev) => ({
                                                        ...prev,
                                                        [item.key]: !prev[item.key],
                                                    }))
                                                }
                                                className=""
                                            >
                                                <i className={
                                                        openEvidence[item.key]
                                                            ? 'fal fa-chevron-up'
                                                            : 'fal fa-chevron-down'
                                                    }
                                                ></i>
                                            </span>}
                                        </div>
                                        <div className={`evidence__bottom__section attachement__box ${openEvidence[item.key] ? 'd-block pb-0' : 'd-none'}`}>
                                            {item?.key && (
                                                <div className="evidence__files__box">
                                                    {item.key == 'evidence_photo' && photoFiles[question.qid]?.length > 0
                                                        ? photoFiles[question.qid].map((f, i) => (
                                                            <div className="col-md-4 evidence__files" key={i}>
                                                                {RenderAttachList({
                                                                    fileName: f?.file_url?.split('PracticalFiles/')[1],
                                                                    size: formatFileSize(f?.file_size),
                                                                    filePath: f?.file_url,
                                                                    showClose: false,
                                                                    i,
                                                                })}
                                                            </div>
                                                        ))
                                                        : item.key == 'evidence_video' && videoFiles[question.qid]?.length > 0
                                                            ? videoFiles[question.qid].map((f, i) => (
                                                                <div className="col-md-4 evidence__files" key={i}>
                                                                    {RenderAttachList({
                                                                    fileName: f?.file_url?.split('PracticalFiles/')[1],
                                                                    size: formatFileSize(f?.file_size),
                                                                    filePath: f?.file_url,
                                                                    showClose: false,
                                                                    i,
                                                                })}
                                                                </div>
                                                            ))
                                                            : item.key == 'evidence_document' && docFiles[question.qid]?.length > 0
                                                                ? docFiles[question.qid].map((f, i) => (
                                                                    <div className="col-md-4 evidence__files" key={i}>
                                                                        {RenderAttachList({
                                                                            fileName: f?.file_url?.split('PracticalFiles/')[1],
                                                                            size: formatFileSize(f?.file_size),
                                                                            filePath: f?.file_url,
                                                                            showClose: false,
                                                                            i,
                                                                        })}
                                                                    </div>
                                                                ))
                                                                : null}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    ) : null}
                {question?.evidence_lecturer_observation ? <div>
                    <div className="d-flex observation__box">
                        <p className="text">Lecturer Observation</p>
                        <div className="custom-toggle-switch ml-2">
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    disabled={true}
                                    checked={question?.lecturer_observation_toggle == 1}
                                    // onChange={(e) => {
                                    //     const newValue = e.target.checked;
                                    //     props.setLecturerObservations((prev) => ({
                                    //         ...prev,
                                    //         [question.qid]: {
                                    //             questionId: question.qid,
                                    //             value: newValue ? 1 : 0,
                                    //             updated: true
                                    //         }
                                    //     }));
                                    //     let updatedQuestion = {
                                    //         ...question,
                                    //         lecturer_observation_toggle: newValue ? 1 : 0,
                                    //         lecture_observation_updated: true
                                    //     }
                                    //     dispatch(setQuiz([`qa${question.qid}qa`, updatedQuestion]));
                                    //     setquestion(updatedQuestion)
                                    //     // const updatedQuizData = quizData.map((quiz) => 
                                    //     //   quiz.qid == question.qid
                                    //     //     ? { ...quiz, lecturer_observation_toggle: newValue ? 1 : 0 }
                                    //     //     : quiz
                                    //     // );

                                    //     // // Dispatch the updated quizData
                                    //     // dispatch(setQuizComplete(updatedQuizData));
                                    // }}
                                />
                                <span class="slider slider-round"></span>
                            </label>
                        </div>
                    </div>
                    <p className="text__3a4s"><i className="far fa-history" /><span>To be completed by lecturer</span></p>
                </div> : null}
            </div>
        );

    }

export default ReviewPracticalQuestion